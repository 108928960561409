// For mat-mdc-select (Dropdown text & options)
::ng-deep .cdk-overlay-container .mat-mdc-select-value {
    font-family: 'Karla', sans-serif !important;
    font-size: 14px !important; // Adjust size
  }
  
  ::ng-deep .cdk-overlay-container .mat-mdc-option {
    font-family: 'Karla', sans-serif !important;
    font-size: 14px !important;
  }
  
  // For mdc-list-item__primary-text (Dropdown list items)
  ::ng-deep .cdk-overlay-container .mdc-list-item__primary-text {
    font-family: 'Karla', sans-serif !important;
    font-size: 14px !important;
  }


.mat-mdc-option {
    .mdc-list-item__primary-text {

        font-family: 'Karla', sans-serif !important;
        font-size: 14px !important;
    }
}
/* You can add global styles to this file, and also import other style files */
// @import '@angular/cdk/overlay-prebuild.css';